<div slot="sign-up" *ngIf="!confirm">
  <div class="section">
    <form #signupForm="ngForm" (ngSubmit)="onSubmit(signupForm)">
      <h3 class="create-account-title">Create New account</h3>

      <label class="label" for="email">Email Address *</label>
      <input #emailInput="ngModel" id="email" type="email" name="email" class="input" placeholder="Enter your email address" ngModel required>
      <div class="alert alert-danger" role="alert"
           *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">Required
      </div>

      <label class="label" for="password">Password *</label>
      <input #passwordInput="ngModel" id="password" name="password" type="password" class="input" minlength="8" placeholder="Enter your password" ngModel required>
      <div class="alert alert-danger" role="alert"
           *ngIf="passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)">Minimum length 8
      </div>

      <span class="checkbox">
      <input id="checkbox" name="checkbox" type="checkbox" ngModel required>
      <label for="checkbox">I have read and agree with <a href="../../../assets/privacy.pdf" target="_blank">privacy policy</a></label>
      </span>

      <re-captcha  [(ngModel)]="captcha"
                   siteKey="6Ld4-M8ZAAAAACT6foObRTqwbck3pgQabFaJGoa_"
                   name="captcha" required>
      </re-captcha>

      <div class="footer">
        <span>Have an account? <a href="">Sign in</a></span>
        <button class="button" type="submit" [disabled]="!signupForm.valid">CREATE ACCOUNT</button>
      </div>
      <div class="alert alert-danger" role="alert" *ngIf="error">{{error}}</div>
    </form>
  </div>
</div>


<amplify-confirm-sign-up *ngIf="confirm" username-alias="email">
</amplify-confirm-sign-up>
