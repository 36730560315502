<div class="row justify-content-md-center">
  <div class="col-md-5 mx-4" *ngIf="answers && behaviour">

    <form (ngSubmit)="onSubmit(form)" #form="ngForm">

      <p *ngIf="behaviour">{{behaviour.text}}</p>

      <div *ngFor="let answer of answers; let i = index" class="custom-control custom-radio">
        <input type="radio" class="custom-control-input"  id="{{answer.score}}" name="answer" value="{{answer.score}}" required ngModel>
        <label  class="custom-control-label" for="{{answer.score}}">{{answer.text}}</label><br>
      </div>

      <div *ngIf="!loading" class="d-flex justify-content-end">
        <button mdbBtn color="primary" class="my-4" type="submit" [disabled]="!form.valid">Next</button>
      </div>

      <div *ngIf="loading" class="d-flex justify-content-end">
        <button *ngIf="loading" mdbBtn color="primary" class="my-4" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </div>

    </form>

    <p class="test-info">This test takes approximately 20 to 30 minutes. The amount of questions is determined dynamically based on answers you give.</p>

  </div>
</div>

<app-loader *ngIf="!behaviour || !answers"></app-loader>
