<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark" [containerInside]="false">

  <mdb-navbar-brand>
    <a class="navbar-brand" routerLink="/app/menu">
      <img src="assets/img/logo.png" alt="" width="160">
    </a>
  </mdb-navbar-brand>
  <links>
    <ul class="navbar-nav ml-auto">
      <ng-container *ngIf="!(loggedIn$ | async)">

        <li class="nav-item">
          <a class="nav-link waves-light" routerLink="" fragment="howitworks">How does It Work</a>
        </li>

        <li class="nav-item">
          <a class="nav-link waves-light" routerLink="" fragment="why">Why</a>
        </li>

        <li class="nav-item">
          <a class="nav-link waves-light" routerLink="" fragment="reviews">Reviews</a>
        </li>

        <li class="nav-item">
          <a class="nav-link waves-light" routerLink="" fragment="forwhom">For Whom</a>
        </li>

        <li class="nav-item">
          <a class="nav-link waves-light" routerLink="" fragment="about">About us</a>
        </li>
      </ng-container>

      <li class="nav-item">
        <a class="nav-link waves-light" href="assets/privacy.pdf" target="_blank">Privacy</a>
      </li>

      <li class="nav-item">
        <a class="nav-link waves-light" href="mailto:info@popconsult.be">Contact us</a>
      </li>

      <li class="nav-item" *ngIf="loggedIn$ | async">
        <a class="nav-link waves-light" mdbWavesEffect (click)="logout()">Logout</a>
      </li>

      <li class="nav-item" *ngIf="!(loggedIn$ | async)">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/app">Login</a>
      </li>

    </ul>
  </links>

</mdb-navbar>


