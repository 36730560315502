import { Injectable } from '@angular/core';
import {forkJoin, from, Observable} from 'rxjs';
import API from '@aws-amplify/api';
import { unparse, parse } from 'papaparse';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor() { }

  downloadData(): Observable<any> {
    return from(API.get('salesSkillsApiGateway', '/api/admin/data', null));
  }

  downloadProfile(email: string): Observable<any> {
    const init = {body: {email} };
    return from(API.post('salesSkillsApiGateway', '/api/admin/profile', init));
  }

  public static parseJsonToCsv(json: string): File {
    return unparse(json);
  }

  generateToken(numberOfTokens: number): Observable<any> {
    const init = { body: {numberOfTokens} };
    return from(API.post('salesSkillsApiGateway', '/api/admin/token', init));
  }

  uploadFiles(files: File[]): Observable<any> {
    return forkJoin(files.map(file => AdminService.parseCsvToJson(file)))
      .pipe(switchMap(jsonData => {
        const params = {
          body: {
            behaviours: jsonData[0],
            answerOptions: jsonData[1],
            algorithmConfig: jsonData[2]
          },
          headers: {}
        };
        return from(API.post('salesSkillsApiGateway', '/api/upload', params));
      }));
  }

  private static parseCsvToJson(csvFile: File): Observable<any> {
    return from(csvFile.text()).pipe(
      map(fileAsString => parse(fileAsString, {
        header: true,
        delimiter: ";",
        skipEmptyLines: 'greedy',
        complete: (result) => result}).data));
  }
}
