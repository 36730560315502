import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { API } from 'aws-amplify';
import Payment, { PaymentUrl } from './payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private apiName = 'salesSkillsApiGateway';

  createPayment(amount: number): Observable<PaymentUrl> {
    return from(API.post(this.apiName, '/api/payments', { body: {amount: amount }}));
  }

  getPayment(id: string): Observable<Payment> {
    return from(API.get(this.apiName, `/api/payments/${id}`, null));
  }
}
