<div class="landing-page">

  <div class="section-row">
    <div class="image-container image-left">
      <img src="../../assets/img/sales-skills-assessment-logo.jpg" alt="Sales skills assessment logo">
    </div>

    <div class="section-column" #section>
      <h1>Test your sales skills with our online tool!</h1>
      <p>
        Create your free account and use the Sales Skills Assessment tool for free to understand your strengths and
        to identify the areas in which you may need additional training or coaching.
        After taking the test, you have the option to purchase a detailed report for only €55.
        The feedback from this report allows you to guide training, plan study & to improve your skills.
      </p>
      <button mdbBtn color="primary" [rounded]="true" routerLink="app">Start your free test</button>
    </div>
  </div>

  <div class="section-row blue-background">
    <div class="image-container image-left">
      <img src="../../assets/img/how-does-it-work.jpg" alt="How does it work">
    </div>
    <div class="section-column" #section id="howitworks">
      <h2>How does it work?</h2>
      <div></div>
      <p>The test is a self-assessment which means that you will be given a series of statements and you have to
        indicate what kind of behaviour you will show.
      </p>
      <p>
        Because the tool is intended as an assessment, you should bear in mind that the test takes between 20 and 25
        minutes.
      </p>
      <p>
        You can stop the test at any time and pick up where you left off afterwards.
      </p>
      <p>
        At the end of the test you will get a score of 100 on your screen. When you score 60/100 for example, this means
        that you have 40% growth potential. After taking the test, you have the option to purchase a detailed report for
        only €55. This report provides you with more information about the items that have been tested, a visual
        representation of your score on the various items as well as an interpretation of your personal score.
      </p>
    </div>
  </div>

  <div class="section-row">
    <div class="section-column" #section id="why">
      <h2>Why?</h2>
      <p>Selling is not easy. Even for the best and most experienced salespeople, it is a continual effort. Further
        developing your sales skills makes your job easier. But developing your sales skills on your own is not easy.
      </p>
      <p>
        Because we care about salespeople, their capabilities to add value every day in customer interactions, their
        possibilities to further develop their skills, we developed a tool to support them in their professional
        journey.
      </p>
      <p>
        Are you looking for support in developing your sales skills at your pace and level, at an affordable price? Be
        sure to use the Sales Skills Assessment tool!
      </p>
    </div>
    <div class="image-container image-right">
      <img src="../../assets/img/quote.png" alt="quote">
    </div>
  </div>

  <div class="section-row blue-background">
    <div class="section-column quote-block" #section id="reviews">
      <h2>Reviews</h2>

      <span class="quote">"Highly recommended, created by a team that walks the talk and that I have the pleasure to work with and learn from."</span>
      - K.M., Customer Success Coach

      <span class="quote">"The result of the test has really helped me to understand what I can work on to grow in my sales role. I can certainly recommend buying the report."</span>
      - J. G., Junior Account Manager

    </div>
  </div>

  <div class="section-row">
    <div class="section-column" #section id="forwhom">
      <h2>For Whom</h2>
      <p>
        Sales Skills Assessment tool is there for everyone who is professionally involved in sales. Both for starters
        and experienced professionals. The assessment is adapted to your function and experience.
      </p>
      <p>
        At the start of the test you can enter your function (business owner, sales advisor, account manager,...) and
        your number of years of experience in sales. Based on this information you will receive a series of questions.
      </p>
      <p>
        Sales Skills Assessment focuses on the use by individuals and focuses on personal development of the sales
        skills.
      </p>
      <p>
        Companies can also use the tool to coach their employees on sales skills. If you, as a company, are interested
        in using the tool, please contact us.
      </p>
    </div>
  </div>

  <div class="section-row blue-background">
    <div class="section-column" #section id="about">
      <h2>About us</h2>
      <div class="divider"></div>
      <h4>A Strong Team</h4>
      <div class="divider"></div>
      <p>
        Sales Skills Assessment originated from the collaboration between Ann Rens and Luc Vanheule.
      </p>
      <p>
        Luc, sales manager in international companies for more than 25 years and Ann, HR expert and coach with more than
        25 years of experience in various sectors.
      </p>
      <p>
        The combination of their experience led to the development of Sales Skills Assessment. The tool was then
        academically validated and extensively tested.
      </p>

      <div class="follow-us">

        <h2>Follow Us</h2>

        <div class="follow-us-icons">
          <a target="_blank" href="https://www.facebook.com/Sales-Skills-Assessment-105893067898855"><i
            class="fab fa-fw fa-3x fa-facebook-square"></i></a>
          <a target="_blank" href="https://www.linkedin.com/company/sales-skills-assessment"><i
            class="fab fa-fw fa-3x fa-linkedin"></i></a>
        </div>
      </div>
    </div>
    <div class="image-container image-right">
      <img src="../../assets/img/Luc-and-Ann.jpg" alt="About us" class="contact-image">
    </div>
  </div>
</div>
<app-footer></app-footer>
