<div class="row justify-content-md-center">
  <div class="col-md-6 mx-4" style="text-align: center">

      <div *ngIf="payment">
        <h3>Thank you for your purchase</h3>
        <p>You will receive an email with your requested tokens soon</p>

        <div class="purchase-summary">
          <div>Detailed report tokens</div>
          <div>{{ payment.amount }}x</div>
          <div>€ {{ payment.totalPrice | number: '1.2-2' }}</div>
        </div>

        <button mdbBtn class="btn btn-primary" type="button" routerLink="/app/menu">Back to home</button>
      </div>

  </div>
</div>


