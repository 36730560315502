import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AssessmentService } from '../assessment.service';
import { Behaviour } from './model/behaviour';
import { Answer } from './model/answer';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-behaviour',
  templateUrl: './behaviour.component.html',
  styleUrls: ['./behaviour.component.scss']
})
export class BehaviourComponent implements OnInit {

  behaviour: Behaviour;
  answers: Answer[];
  loading = false;

  constructor(
    private assessmentService: AssessmentService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.assessmentService.getNextBehaviour().subscribe(behaviour => this.behaviour = behaviour);
    this.assessmentService.getBehaviourAnswers().subscribe(answers => this.answers = answers);
  }

  onSubmit(form: NgForm) {
    this.loading = true;
    const score = form.form.value.answer;
    this.assessmentService.saveAnswer(this.behaviour, score).pipe(
      switchMap(() => this.assessmentService.getNextBehaviour()))
      .subscribe(behaviour => {
          if (!behaviour) this.router.navigate(['/app/score']);
          else {
            this.loading = false;
            this.behaviour = behaviour;
            form.reset();
          }
        }
      );
  }
}
