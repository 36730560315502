<div class="row justify-content-md-center">
  <div class="col-md-6 mx-4" style="text-align: center">
    <h1>Thanks for taking your sales skills test!</h1>
    <br>
    <h2>Your final score: <br> {{score}}/100</h2>
    <br>
  </div>
</div>

<div class="row justify-content-md-center">

  <div class="col-md-6 mx-4 ">
    <p>How to read your score? Based on the input you gave in the different scenario’s, we calculated an overall score
      as an average over the 7 axis:</p>

    <ul>
      <li>Communication with impact</li>
      <li>Customer Relationship building</li>
      <li>Industry & Application knowledge</li>
      <li>Planning & Preparation</li>
      <li>Qualifying & Needs Analysis</li>
      <li>Solution Presentation</li>
      <li>Closing</li>
    </ul>

    <p>This score charts in a numerical way the growth potential to further develop your sales skills. It is important
      that you read the score as indicator for your future growth potential.
    </p>
    <p>Example: If you score 42%. This shows you have a potential of 58% to improve on your sales skills. </p>
    <p>You can perform this test again each 6 months to measure your progress.</p>
    <p>
      If you want to learn more on your scores per axis, you can opt for the detailed report, which you can purchase for
      a small amount. This report charts your talents as well as the skills to improve. Based on this report, you can
      take further actions to grow in your sales skills.
      You purchase a voucher on the starting page, giving you access to your detailed report. The report will be send to
      the e-mail address you used as log-in. <a routerLink="/app/payment">Buy now!</a>
    </p>

    <p>To receive a more detailed report of your results, enter your voucher code.</p>
    <form class="text-left" (ngSubmit)="onSubmit(codeForm)" #codeForm="ngForm">
      <input mdbInput mdbValidate type="text" class="form-control" ngModel id="code" name="code"
             placeholder="Enter voucher code" required #code="ngModel">
      <button *ngIf="!sending"
              mdbBtn color="primary" block="true" class="my-4" type="submit" [disabled]="!codeForm.valid">Send report
      </button>
      <button *ngIf="sending"
              mdbBtn color="primary" block="true" class="my-4" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Sending report...
      </button>
      <div *ngIf="error" class="error">
        {{error}}
      </div>
      <div *ngIf="success" class="success">
        {{success}}
      </div>
    </form>
    <br>
  </div>

</div>

