<form  (ngSubmit)="onSubmit(form)" #form="ngForm">
  <div class="form">

    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">E-mail</span>
      </div>
      <input mdbInput mdbValidate type="email" class="form-control" ngModel id="userEmail" name="userEmail" required #email = "ngModel">
      <div class="alert alert-danger" role="alert"
           *ngIf="!email.valid && email.touched">Required
      </div>
    </div>

    <button *ngIf="!downLoadingProfile" type="submit" mdbBtn class="btn btn-outline-primary" [disabled]="email.invalid">Export profile data</button>
    <button *ngIf="downLoadingProfile" mdbBtn color="primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Downloading...
    </button>
    <div *ngIf="errorMessage" class="error">
      {{'Something went wrong: '}}<br>
      {{errorMessage}}
    </div>
  </div>
</form>

<br> <br>

<div class="form">
  <button *ngIf="!downLoadingData" mdbBtn class="btn btn-outline-primary" (click)="exportData()">Export overall data</button>
  <button *ngIf="downLoadingData" mdbBtn color="primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Downloading...
  </button>
</div>

