import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import {FormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { StartAssessmentComponent } from './assessment/start-assessment/start-assessment.component';
import { BehaviourComponent } from './assessment/behaviour/behaviour.component';
import { HeaderComponent } from './header/header.component';
import { AdminComponent } from './admin/admin.component';
import { ScoreComponent } from './assessment/score/score.component';
import { PopconsultSignUpComponent } from './auth/popconsult-sign-up/popconsult-sign-up.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreatePaymentComponent } from './payment/create-payment/create-payment.component';
import { GenerateTokensComponent } from './admin/generate-tokens/generate-tokens.component';
import { UploadFilesComponent } from './admin/upload-files/upload-files.component';
import { AfterPaymentComponent } from './payment/after-payment/after-payment.component';
import { RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha';
import { ExportDataComponent } from './admin/export-data/export-data.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LandingComponent } from './landing/landing.component';
import { AuthComponent } from './auth/auth.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    StartAssessmentComponent,
    BehaviourComponent,
    AdminComponent,
    HeaderComponent,
    ScoreComponent,
    PopconsultSignUpComponent,
    CreatePaymentComponent,
    AfterPaymentComponent,
    GenerateTokensComponent,
    UploadFilesComponent,
    ExportDataComponent,
    LoaderComponent,
    LandingComponent,
    AuthComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
