<footer class="page-footer font-small">
  <mdb-navbar-brand>
    <a class="navbar-brand" routerLink="/app/menu">
      <img src="assets/img/logo.png" alt="" width="160">
    </a>
  </mdb-navbar-brand>

  © POP Consult & Coaching

</footer>
