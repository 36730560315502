import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {AssessmentService} from '../assessment.service';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {
  score: number;
  error: string;
  success: string;
  sending = false;

  constructor(
    private assessmentService: AssessmentService
  ) { }

  ngOnInit(): void {
    this.assessmentService.getFinalScore().subscribe(score => this.score = score);
  }

  onSubmit(codeForm: NgForm) {
    this.sending = true;
    this.assessmentService.requestReport(codeForm.form.value.code).subscribe(
      (error) => {
        if (!error) {this.success = 'You will receive an e-mail with the report soon. This can take up to 10 minutes.'; }
        this.error = error;
        this.sending = false;
        codeForm.reset();
      }
    );
  }
}
