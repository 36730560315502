export default class User {

  constructor(
    public authenticated: boolean,
    public id: string,
    public email: string,
    public group: UserGroup
  ) {
  }

  static fromDto(dto: JwtPayload): User {
    if (!!dto) {
      return new User(
        true,
        dto.sub,
        dto.email,
        dto['cognito:groups'] ? UserGroup[dto['cognito:groups'][0].toUpperCase()] : UserGroup.USER
      );
    } else {
      return User.anonymousUser();
    }
  }

  static anonymousUser(): User {
    return new User(false, null, null, null);
  }

  isAdmin(): boolean {
    return this.group === UserGroup.ADMIN;
  }

  isRegularUser(): boolean {
    return this.group === UserGroup.USER;
  }
}

export interface JwtPayload {
  sub: string;
  email: string;
  email_verified: boolean;
  'cognito:groups': string[];
}

export enum UserGroup {
  ADMIN = 'ADMIN',
  USER = 'USER',
}
