import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent {

  @Output() onFilesUpload: EventEmitter<any> = new EventEmitter<any>();
  @Input() uploading = false;
  @Input() errorMessage: string;
  behavioursFile: File;
  answerOptionsFile: File;
  algorithmConfigFile: File;

  constructor() { }

  onChangeBehaviourFile(files: any) {
    this.behavioursFile = files.item(0);
  }

  onChangeAnswerFile(files: any) {
    this.answerOptionsFile = files.item(0);
  }

  onChangeAlgorithmConfig(files: any) {
    this.algorithmConfigFile = files.item(0);
  }

  onSubmit() {
    const behaviours = this.behavioursFile;
    const answers = this.answerOptionsFile;
    const config = this.algorithmConfigFile;
    this.onFilesUpload.emit({behaviours, answers, config});
  }
}
