import {Injectable} from '@angular/core';
import {API} from 'aws-amplify';
import ExperienceType from './experience-type';
import {from, Observable} from 'rxjs';
import {Behaviour} from './behaviour/model/behaviour';
import {Answer} from './behaviour/model/answer';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  private apiName = 'salesSkillsApiGateway';

  constructor() { }

  createAssessment(yearsOfExperience: number, typeOfExperience: ExperienceType, jobTitle: string): Observable<any> {
    const init = { body: {yearsOfExperience, typeOfExperience, jobTitle} };
    return from(API.post(this.apiName, '/api/assessments', init));
  }

  assessmentIsTooRecent(): Observable<any> {
    return from(API.get(this.apiName, '/api/assessments/recent', null));
  }

  getNextBehaviour(): Observable<Behaviour> {
    return from(API.get(this.apiName, '/api/assessments/behaviour/next', null));
  }

  getBehaviourAnswers(): Observable<Answer[]>{
    return from(API.get(this.apiName, '/api/answer-options', null));
  }

  saveAnswer(behaviour: Behaviour, answer: number): Observable<any> {
    const init = { body: {behaviour, answer} };
    return from(API.put(this.apiName, '/api/assessments/behaviour', init));
  }

  getFinalScore(): Observable<any> {
    return from(API.get(this.apiName, '/api/assessments/score', null));
  }

  requestReport(token: string): Observable<any> {
    const init = { body: {token} };
    return from(API.post(this.apiName, '/api/tokens/email', init));
  }
}
