<div class="row justify-content-md-center" *ngIf="ready">
  <div class="col-md-6">
    <a routerLink="/app/{{startLink}}"><button type="button" block="true" mdbBtn color="primary">{{startButtonText}}</button></a>
    <br>
    <a routerLink="/app/payment"><button type="button" block="true" mdbBtn color="primary">Buy tokens</button></a>
    <br>
    <a *ngIf="isAdmin$ | async" routerLink="/app/admin">
      <button type="button" block="true" mdbBtn color="primary">Admin</button>
    </a>
  </div>
</div>

<app-loader *ngIf="!ready"></app-loader>
