<form  (ngSubmit)="onSubmit(tokenForm)" #tokenForm="ngForm">
  <div class="form">

    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Number of tokens</span>
      </div>
      <input mdbInput mdbValidate type="number" class="form-control" ngModel id="nrOfTokens" name="nrOfTokens" min="0" max="50" required #nrOfTokens = "ngModel">
      <div class="alert alert-danger" role="alert"
           *ngIf="!nrOfTokens.valid && nrOfTokens.touched">Please enter a number
      </div>
    </div>

    <button *ngIf="!generating" type="submit" mdbBtn class="btn btn-outline-primary" [disabled]="tokenForm.invalid">Order vouchers</button>
    <button *ngIf="generating" mdbBtn color="primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Sending vouchers...
    </button>

  </div>
</form>
