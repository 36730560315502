import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-generate-tokens',
  templateUrl: './generate-tokens.component.html',
  styleUrls: ['./generate-tokens.component.scss']
})
export class GenerateTokensComponent implements OnInit {
  @Output() tokensOrdered: EventEmitter<any> = new EventEmitter<any>();
  @Input() generating = false;

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(tokenForm: NgForm) {
    this.tokensOrdered.emit(tokenForm.form.value.nrOfTokens);
  }
}
