/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "assessment-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "behaviour-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "algorithm-config-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "answer-option-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "token-prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "salesSkillsApiGateway",
            "endpoint": "https://6u3opo3us1.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:7a63ee00-7884-4466-b6a9-045e329ea5ba",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_fa8wKcdLs",
    "aws_user_pools_web_client_id": "gtii4ll1duttd7hqkdtq5km27",
    "oauth": {}
};


export default awsmobile;
