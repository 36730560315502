import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { MenuComponent } from './menu/menu.component';
import { StartAssessmentComponent } from './assessment/start-assessment/start-assessment.component';
import { BehaviourComponent } from './assessment/behaviour/behaviour.component';
import { ScoreComponent } from './assessment/score/score.component';
import { IsAdminGuard } from './auth/is-admin.guard';
import { CreatePaymentComponent } from './payment/create-payment/create-payment.component';
import { AfterPaymentComponent } from './payment/after-payment/after-payment.component';
import { LandingComponent } from './landing/landing.component';
import { AuthComponent } from './auth/auth.component';


const routes: Routes = [
  {path: '', pathMatch: 'full', component: LandingComponent},
  {path: 'app', component: AuthComponent, children: [
      {path: 'menu', component: MenuComponent},
      {path: 'start', component: StartAssessmentComponent},
      {path: 'behaviour', component: BehaviourComponent},
      {path: 'score', component: ScoreComponent},
      {path: 'payment', component: CreatePaymentComponent},
      {path: 'payment/:id', component: AfterPaymentComponent},
      {path: 'admin', component: AdminComponent, canActivate: [IsAdminGuard]},
    ]},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
