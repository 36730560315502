<div class="row justify-content-md-center">
  <div class="col-md-6">

    <p>This test takes approximately 20 to 30 minutes.</p>

      <form class="text-left border border-light p-5" (ngSubmit)="onSubmit(startForm)" #startForm="ngForm">

        <label for="title">Job Title</label>
        <select class="browser-default custom-select" mdbInput mdbValidate ngModel id="title" name="title" required #title = "ngModel">
          <option *ngFor="let title of jobTitles" value="{{title}}">{{title}}</option>
        </select>
        <div class="alert alert-danger" role="alert"
             *ngIf="!title.valid && title.touched">Please select an option
        </div>
        <br>
        <br>
        <label for="yearsOfExperience">Years of Experience</label>
        <input mdbInput mdbValidate type="number" class="form-control" ngModel id="yearsOfExperience" name="yearsOfExperience" placeholder="Enter your years of experience" min="0" max="50" required #experience = "ngModel">
        <div class="alert alert-danger" role="alert"
             *ngIf="!experience.valid && experience.touched">Please enter a number
        </div>
        <br>

        <p>Type of Experience:</p>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input"  id="bb" name="type" value="B2B" required ngModel>
          <label  class="custom-control-label" for="bb">B2B</label><br>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="bc" name="type" value="B2C" required ngModel>
          <label class="custom-control-label" for="bc">B2C</label><br>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="both" name="type" value="BOTH" required ngModel>
          <label class="custom-control-label" for="both">Both</label><br>
        </div>

        <button mdbBtn color="primary" block="true" class="my-4" type="submit" [disabled]="!startForm.valid">Next</button>

      </form>

  </div>
</div>
