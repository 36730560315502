import { Injectable, NgZone } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import { defer, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import User, { JwtPayload } from './user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUser$: Subject<User> = new ReplaySubject<User>(1);

  constructor(private router: Router, private ngZone: NgZone) {
    this.registerOnAuthEvents();
    this.refreshUser();
  }

  getCurrentUser(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  logout() {
    Auth.signOut();
  }

  private registerOnAuthEvents() {
    Hub.listen('auth', data => this.ngZone.run(() => this.handleAuthEvent(data.payload.event)));
  }

  private handleAuthEvent(event: string) {
    if (event === 'signIn') {
      this.refreshUser();
      this.router.navigate(['app', 'menu']);
    } else if (event === "signOut"){
      this.reset();
    }
  }

  private refreshUser() {
    defer(() => Auth.currentSession())
      .pipe(
        map(session => session.getIdToken()),
        catchError(() => of(null)),
        map(token => User.fromDto(token?.payload as JwtPayload)),
        tap(user => this.currentUser$.next(user)))
      .subscribe();
  }

  private reset() {
    this.currentUser$.next(User.anonymousUser());
  }
}
