<div class="row justify-content-md-center">
  <div class="col-md-6 mx-4" style="text-align: center">
    <h2>Buy tokens</h2>

    <p>These tokens can be used to receive a detailed report after you finished the assessment.
      You can buy one token for yourself or multiple to share with others.
    </p>

    <div class="shopping-cart">
      <div>Detailed report tokens</div>
        <div class="number-input">
          <input type="number" (change)="onChange($event)" value="{{amount}}" min="0" max="100"/>
        </div>
        <div>€ {{ totalPrice | number: '1.2-2' }}</div>
    </div>


    <div class="total-price">
      Total: € {{ totalPrice | number: '1.2-2' }}
    </div>

    <button *ngIf="!loading" mdbBtn class="btn btn-primary" type="submit" (click)="onPay()" [disabled]="amount <= 0 ">
      Pay
    </button>

    <button *ngIf="loading" mdbBtn color="primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>

  </div>
</div>



