import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing',
  templateUrl: 'landing.component.html',
  styleUrls: ['landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscription: Subscription;
  @ViewChildren('section') sections: QueryList<any>;

  constructor(private authService: AuthService, private router: Router, private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscription = this.authService.getCurrentUser().subscribe(
      user => {
        if (user.authenticated) {
          this.router.navigate(['app', 'menu']);
        }
      });
  }

  ngAfterViewInit() {
    this.activeRoute.fragment.subscribe(fragment => this.scrollToSection(fragment));
  }

  private scrollToSection(fragment: string) {
    if (fragment) {
      const scrollDistance = this.sections.toArray()
        .find(item => item.nativeElement.id === fragment).nativeElement.offsetTop;
      window.scrollTo({top: scrollDistance - 30});
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
