// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-popconsult-sign-up',
  templateUrl: './popconsult-sign-up.component.html',
  styleUrls: ['./popconsult-sign-up.component.scss']
})
export class PopconsultSignUpComponent implements OnInit {

  confirm = false;
  error: string;
  captcha: any;

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(signUpForm: NgForm) {

    Auth.signUp({
      username: signUpForm.form.value.email,
      password: signUpForm.form.value.password,
      validationData: [{
        Name: 'recaptchaToken',
        Value: this.captcha
      }]
    })
      .then(() => {
        this.confirm = true;
        this.error = null;
      })
      .catch((error) => {
        if (error?.code === 'UsernameExistsException') {
          this.error = error.message;
        }
      });
  }
}
