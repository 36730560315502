import { Component, OnInit } from '@angular/core';
import { FormFieldTypes } from '@aws-amplify/ui-components';
import { Observable } from 'rxjs';
import User from './user';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss']
})
export class AuthComponent implements OnInit {

  user$: Observable<User>;
  formFields: FormFieldTypes = [
    {
      type: 'email',
      required: true,
    },
    {
      type: 'password',
      required: true,
    }
  ];

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.user$ = this.authService.getCurrentUser();
  }
}
