import { Component } from '@angular/core';
import {AdminService} from './admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {

  uploading = false;
  generating = false;
  downloadingProfile = false;
  downloadingData = false;
  uploadErrorMessage = '';
  downloadErrorMessage = '';

  constructor(
    private service: AdminService
  ) { }

  orderTokens(nrOfTokens: number) {
    this.generating = true;
    this.service.generateToken(nrOfTokens)
      .subscribe(
        () => {},
        () => {
          this.generating = false;
        },
          () => {
          this.generating = false;
          }
      );
  }

  uploadFiles({behaviours, answers, config}: any) {
    this.uploading = true;
    this.uploadErrorMessage = '';
    this.service.uploadFiles([
      behaviours,
      answers,
      config
    ]).subscribe(
      () => {},
      (error) => {
        this.uploading = false;
        this.uploadErrorMessage = error.response.data.message;
      },
      () => {
        this.uploading = false;
      }
    );
  }

  exportData() {
    this.downloadingData = true;
    this.service.downloadData().subscribe(
      (data) => {
        this.downloadFile(data);
        this.downloadingData = false;
      },
    () => {this.downloadingData = false; }
  );
  }

  exportProfiles(email: string) {
    this.downloadingProfile = true;
    this.service.downloadProfile(email).subscribe(
      (data) => {
        this.downloadFile(data);
        this.downloadingProfile = false;
      },
      (error) => {
        this.downloadErrorMessage = error.response.data.message;
        this.downloadingProfile = false;
      }
    );
  }

  downloadFile(data: any) {
    const dataFile = AdminService.parseJsonToCsv(data);
    const blob = new Blob([dataFile], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}
