import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../payment.service';
import Payment  from '../payment';

@Component({
  selector: 'app-after-payment',
  templateUrl: 'after-payment.component.html',
  styleUrls: ['after-payment.component.scss']
})
export class AfterPaymentComponent implements OnInit {

  payment: Payment;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
  ) {
  }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params.id;
    this.paymentService.getPayment(id)
      .subscribe(payment => {
          payment.paymentStatus == 'PAID' ? this.payment = payment : this.router.navigate(['/app/menu'])
        },
        () => this.router.navigate(['/app/menu'])
      );
  }

}
