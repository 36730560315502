import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss']
})
export class ExportDataComponent implements OnInit {
  @Output() onExportProfiles: EventEmitter<any> = new EventEmitter<any>();
  @Output() onExportData: EventEmitter<any> = new EventEmitter<any>();
  @Input() downLoadingProfile = false;
  @Input() downLoadingData = false;
  @Input() errorMessage = '';

  constructor() { }

  ngOnInit(): void {
  }

  exportData() {
    this.onExportData.emit();
  }

  onSubmit(form: NgForm) {
    this.onExportProfiles.emit(form.form.value.userEmail);
  }
}
