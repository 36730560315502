import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../assessment.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start-assessment',
  templateUrl: './start-assessment.component.html',
  styleUrls: ['./start-assessment.component.scss']
})
export class StartAssessmentComponent implements OnInit {
  jobTitles = [
    'Account Executive',
    'Account manager',
    'Business Development Manager',
    'Business owner',
    'Field Sales Representative',
    'Freelance professional',
    'Inside Sales',
    'Key Account Manager',
    'Managing Director',
    'Sales Manager',
    'Store Client Advisor',
    'Tele Sales Representative',
    'Others',
  ];
  constructor(
    private assessmentService: AssessmentService,
    private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    const title = form.form.value.title;
    const yearsOfExperience = form.form.value.yearsOfExperience;
    let typeOfExperience = form.form.value.type;
    if (typeOfExperience === 'BOTH') {
      typeOfExperience = 'B2B'
    }
    this.assessmentService.createAssessment(yearsOfExperience, typeOfExperience, title)
      .subscribe(() => {
        this.router.navigate(['/app/behaviour']);
      });
  }
}
