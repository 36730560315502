<div class="row justify-content-md-center">
  <div class="col-md-6 ">
    <h3>Upload new csv-files to configure the assessment</h3>
  </div>
</div>
<app-upload-files
  (onFilesUpload)="uploadFiles($event)"
  [errorMessage]="uploadErrorMessage"
  [uploading]="uploading"
></app-upload-files>
<br>
<br>
<div class="row justify-content-md-center">
  <div class="col-md-6 ">
    <h3>Order vouchers</h3>
  </div>
</div>
<app-generate-tokens
  (tokensOrdered)="orderTokens($event)"
  [generating]="generating"
></app-generate-tokens>
<br>
<br>
<div class="row justify-content-md-center">
  <div class="col-md-6 ">
    <h3>Export data</h3>
  </div>
</div>

<app-export-data
  (onExportData)="exportData()"
  (onExportProfiles)="exportProfiles($event)"
  [downLoadingProfile]="downloadingProfile"
  [downLoadingData]="downloadingData"
  [errorMessage]="downloadErrorMessage"
></app-export-data>
