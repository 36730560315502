import { Component } from '@angular/core';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-create-payment',
  templateUrl: 'create-payment.component.html',
  styleUrls: ['create-payment.component.scss']
})
export class CreatePaymentComponent {

  amount: number = 0;
  unitPrice: number = 55.0;
  totalPrice: number = 0.00;
  loading = false;

  constructor(private paymentService: PaymentService) {
  }

  onChange(event) {
    this.amount = Math.max(Math.round(event.target.value), 0);
    this.totalPrice = this.unitPrice * this.amount;
  }

  onPay() {
    this.loading = true;
    this.paymentService.createPayment(this.amount)
      .subscribe(paymentUrl => {
          window.open(paymentUrl.href, '_self')
          this.loading = false;
      });
  }
}
