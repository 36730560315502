<form (ngSubmit)="onSubmit()" #f="ngForm">
  <div class="form">

    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Behaviours</span>
      </div>
      <div class="custom-file">
        <input type="file" accept="text/csv" class="custom-file-input" id="behaviours"
               (change)="onChangeBehaviourFile($event.target.files)"
               name="behavioursFile" ngModel required>
        <label class="custom-file-label" for="behaviours">{{behavioursFile?.name || "Choose file"}}</label>
      </div>
    </div>


    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Answer options</span>
      </div>
      <div class="custom-file">
        <input type="file" accept="text/csv" class="custom-file-input" id="answers"
               (change)="onChangeAnswerFile($event.target.files)"
               name="answerOptionsFile" ngModel required>
        <label class="custom-file-label" for="answers">{{answerOptionsFile?.name || "Choose file"}}</label>
      </div>
    </div>


    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Algorithm params</span>
      </div>
      <div class="custom-file">
        <input type="file" accept="text/csv" class="custom-file-input" id="algorithmConfig"
               (change)="onChangeAlgorithmConfig($event.target.files)"
               name="algorithmFile" ngModel required>
        <label class="custom-file-label" for="algorithmConfig">{{algorithmConfigFile?.name || "Choose file"}}</label>
      </div>
    </div>

    <button *ngIf="!uploading" mdbBtn class="btn btn-outline-primary" type="submit" [disabled]="f.invalid">Upload
    </button>

    <button *ngIf="uploading" mdbBtn color="primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Uploading...
    </button>

    <div *ngIf="errorMessage" class="error">
      {{'Something went wrong, please check the content files: '}}<br>
      {{errorMessage}}
    </div>

  </div>
</form>

