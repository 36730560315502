<amplify-authenticator username-alias="email">
  <app-popconsult-sign-up
    slot="sign-up">
  </app-popconsult-sign-up>

  <ng-container *ngIf="user$ | async as user">
    <div class="main-app" *ngIf="user.authenticated">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>

</amplify-authenticator>

<div [class.background-image]="!(user$ | async).authenticated"></div>
