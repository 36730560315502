import { Component, OnInit } from '@angular/core';
import {AssessmentService} from '../assessment/assessment.service';
import {AuthService} from '../auth/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  startLink = '/start';
  startButtonText = 'Start assessment';
  ready = false;
  isAdmin$: Observable<boolean>;

  constructor(
    private assessmentService: AssessmentService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isAdmin$ = this.authService.getCurrentUser().pipe(map(user => user.isAdmin()));

    this.assessmentService.assessmentIsTooRecent().subscribe((state) => {
      if (state === 'score') {
        this.startLink = '/score';
        this.startButtonText = 'Latest score';
      }
      else if (state === 'continue') {
        this.startLink = '/behaviour';
        this.startButtonText = 'Continue assessment';
      }
      this.ready = true;
    });
  }

}
